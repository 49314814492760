@charset "UTF-8";
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

br {
  font-size: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

a,
a:focus,
button,
button:focus,
input,
input:focus {
  outline: none;
}

a {
  text-decoration: none;
}

button {
  border: none;
}

::-moz-focus-inner,
::-moz-focus-outer {
  border: 0;
  padding: 0;
}

/**
 * Remove outlines in Firefox.
 */
select:-moz-focusring,
a:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

*:focus,
*:visited,
*:active,
*:hover {
  outline: 0 !important;
}

a,
a:focus,
a:visited,
a:active,
a:hover {
  outline: 0 !important;
}

*::-moz-focus-inner {
  border: 0;
  outline: none;
}

html, body {
  height: 100%;
  scroll-behavior: smooth;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

main {
  flex: 1 1 auto;
}

body {
  position: relative;
  font-family: "Verdana", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.3;
  color: #171219;
  background: white;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

svg {
  overflow: hidden;
}

button {
  font-family: "Verdana", Arial, sans-serif;
  display: block;
  padding: 0;
  background: transparent;
}

.container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (min-width: 768px) {
  .container {
    width: 730px;
  }
}
@media screen and (min-width: 1024px) {
  .container {
    width: 1024px;
  }
}
@media screen and (min-width: 1220px) {
  .container {
    width: 1220px;
  }
}
@media screen and (min-width: 1440px) {
  .container {
    width: 1280px;
  }
}

.btn {
  text-align: center;
  font-weight: 700;
  width: 100%;
  display: block;
  padding: 18px;
  background-color: #51225D;
  color: white;
  font-size: 16px;
  margin: 0 auto;
  cursor: pointer;
  transition: 0.8s;
  text-transform: uppercase;
  border-radius: 0;
  border: unset;
}
.btn:hover {
  text-decoration: underline;
  background-color: #450954;
  border-color: #51225D;
}
.btn:active {
  background-color: white;
  color: #51225D;
  text-decoration: none;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline;
}

.title {
  text-transform: uppercase;
  font-size: 18px;
  color: #171219;
  line-height: 37px;
  margin-bottom: 18px;
}
@media screen and (min-width: 1024px) {
  .title {
    font-size: 20px;
    margin-bottom: 16px;
    line-height: 34px;
  }
}

.subtitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .subtitle {
    font-size: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .subtitle {
    font-size: 28px;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.slick-slider {
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #FFFFFF;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: "←";
}

[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: "→";
}

[dir=rtl] .slick-next:before {
  content: "←";
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  left: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.slick-slide {
  outline: none;
}

.block {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .block {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .block {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (min-width: 1220px) {
  .block {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

.header {
  padding-top: 15px;
  padding-bottom: 10px;
  background: white;
  z-index: 9;
  border-bottom: 1px solid rgba(127, 127, 127, 0.5);
}
@media screen and (min-width: 768px) {
  .header {
    border-bottom: none;
  }
}

.header .container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

/* Гамбургер иконка */
.burger {
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  background: #51225D;
  z-index: 10;
}
@media screen and (min-width: 768px) {
  .burger {
    display: none;
  }
}

.burger span {
  width: 14px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
}

.burger span:nth-of-type(2) {
  top: 10px;
  right: 0;
}

.burger span:nth-of-type(3) {
  top: auto;
  right: 0;
  bottom: 8px;
}

/* Меняем гамбургер иконку, когда меню открыто */
.burger.active span:nth-of-type(1) {
  display: none;
}

.burger.active span:nth-of-type(2) {
  width: 14px;
  top: 50%;
  left: 9px;
  transform: translate(0%) rotate(45deg);
}

.burger.active span:nth-of-type(3) {
  width: 14px;
  top: 50%;
  bottom: 0;
  transform: translate(-50%, 0%) rotate(-45deg);
}

.hidden {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

.nav {
  width: 100%;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, -999px);
}
@media screen and (min-width: 768px) {
  .nav {
    height: auto;
    width: 100%;
    position: static;
    transform: translate(0);
    background: transparent;
    box-shadow: none;
    backdrop-filter: none;
  }
}

.nav .logo, .logo {
  width: 100%;
  max-width: 140px;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .nav .logo, .logo {
    max-width: 130px;
  }
}
@media screen and (min-width: 1024px) {
  .nav .logo, .logo {
    max-width: 160px;
  }
}

@media screen and (min-width: 768px) {
  .logo-mob {
    display: none;
  }
}

.logo-desk {
  display: none;
}
@media screen and (min-width: 768px) {
  .logo-desk {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .nav.active {
    height: calc(100vh - 50px);
    overflow: auto;
    width: 100%;
    padding: 10px 24px 80px;
    transform: translateX(-50%);
    z-index: 10;
    background: #fff;
  }
}

.nav__wrapper {
  max-width: 325px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 640px) {
  .nav__wrapper {
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .nav__wrapper {
    display: flex;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .nav__wrapper {
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: space-between;
  }
}

.links {
  margin-bottom: 10px;
}
@media screen and (min-width: 640px) {
  .links {
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .links {
    order: 1;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 0;
    border: none;
    display: flex;
  }
}
@media screen and (min-width: 1024px) {
  .links {
    width: auto;
    gap: 10px;
  }
}

.links.active {
  cursor: default;
}

.links__link {
  position: relative;
  transition: all 0.5s;
  font-weight: 500;
  width: 100%;
  max-width: 244px;
  font-size: 18px;
  text-align: center;
  display: block;
  color: black;
  margin: 0 auto;
  padding: 10px;
}
@media screen and (min-width: 768px) {
  .links__link {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .links__link {
    cursor: pointer;
  }
}

.links__link.active {
  cursor: default;
  color: #51225D;
}

.links__title {
  position: relative;
  font-weight: 400;
  width: 100%;
  max-width: 244px;
  font-size: 18px;
  text-align: center;
  display: block;
  color: #171219;
  margin: 0 auto;
  padding: 10px;
}

@media screen and (min-width: 640px) {
  .links {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 768px) {
  .links {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 640px) {
  .links__link {
    max-width: 200px;
  }
}

.links__link.active {
  position: relative;
  font-weight: bold;
}
.links__link.active:before {
  position: absolute;
  content: "";
  top: 50%;
  left: -4px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background: url("../img/play-arrow.svg") no-repeat;
  background-size: 100%;
}

.pages {
  margin-bottom: 20px;
}
@media screen and (min-width: 640px) {
  .pages {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 20px;
    justify-content: center;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 768px) {
  .pages {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    justify-content: flex-start;
    gap: 10px;
    order: 3;
  }
}
@media screen and (min-width: 1024px) {
  .pages {
    margin: 0;
    gap: 30px;
    order: 0;
  }
}

.pages__link {
  max-width: 325px;
  width: 100%;
  font-size: 18px;
  text-align: center;
  display: block;
  padding: 10px;
  color: #171219;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .pages__link {
    color: #7F7F7F;
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .pages__link {
    cursor: pointer;
  }
}

@media screen and (min-width: 768px) {
  .pages__wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .logo__link {
    width: 100%;
  }
}
@media screen and (min-width: 1220px) {
  .logo__link {
    width: auto;
  }
}

.pages__item:last-child {
  font-weight: 700;
}

.pages-link {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .pages-link {
    display: none;
  }
}
.pages-link__link {
  color: #000;
  font-size: 18px;
  font-weight: 700;
}

.pages__wrapper {
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.pages__wrapper:before {
  position: absolute;
  content: "";
  width: 100vw;
  height: 1px;
  background: rgba(127, 127, 127, 0.5);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.quiz-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px auto 0;
}
@media screen and (min-width: 768px) {
  .quiz-link {
    order: 2;
    flex-direction: column;
  }
}
@media screen and (min-width: 1024px) {
  .quiz-link {
    flex-direction: row;
    margin: 0;
  }
}

.quiz-link__text {
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .quiz-link__text {
    font-size: 14px;
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 1024px) {
  .quiz-link__text {
    white-space: nowrap;
    margin-bottom: 0;
    margin-right: 5px;
  }
}

.quiz-link__btn {
  text-align: center;
  max-width: 315px;
  width: 100%;
  font-weight: bold;
  background: #51225D;
  padding: 16px 18px;
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 0;
}
.quiz-link__btn:hover, .quiz-link__btn:active {
  text-decoration: underline;
}
@media screen and (min-width: 768px) {
  .quiz-link__btn {
    padding: 0;
    color: #171219;
    background: transparent;
  }
}
@media screen and (min-width: 1024px) {
  .quiz-link__btn {
    cursor: pointer;
    position: relative;
    padding-left: 15px;
  }
  .quiz-link__btn:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 10px;
    height: 10px;
    background: url("../img/play-arrow.svg") no-repeat;
    background-size: 100%;
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 360px) {
  .quiz-link__btn {
    padding: 16px 30px;
  }
}

.rate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .rate {
    margin-bottom: 0px;
    order: 2;
    flex-direction: row;
  }
}
@media screen and (min-width: 1024px) {
  .rate {
    flex-direction: row;
  }
}

.rate-text {
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .rate-text {
    font-size: 14px;
    margin-bottom: 0px;
    white-space: nowrap;
    margin-right: 5px;
  }
}

.rate-btn {
  text-align: center;
  max-width: 310px;
  width: 100%;
  font-weight: bold;
  background: #51225D;
  padding: 16px 18px;
  color: white;
  text-transform: uppercase;
  font-size: 16px;
}
.rate-btn:hover, .rate-btn:active {
  text-decoration: underline;
}
@media screen and (min-width: 360px) {
  .rate-btn {
    padding: 16px 30px;
  }
}
@media screen and (min-width: 768px) {
  .rate-btn {
    padding: 0;
    color: #171219;
    background: transparent;
  }
}
@media screen and (min-width: 1024px) {
  .rate-btn {
    cursor: pointer;
    position: relative;
    padding-left: 15px;
  }
  .rate-btn:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 10px;
    height: 10px;
    background: url("../img/play-arrow.svg") no-repeat;
    background-size: 100%;
    transform: translateY(-50%);
  }
}

.promo {
  overflow: hidden;
  background: #97c9e8;
  padding-top: 30px;
}
@media screen and (min-width: 640px) {
  .promo {
    padding-top: 0;
  }
}
.promo_pages {
  padding: 24px 0;
}
@media screen and (min-width: 480px) {
  .promo_pages {
    padding: 48px 0;
  }
}
@media screen and (min-width: 640px) {
  .promo_pages {
    padding-top: 48px;
  }
}
@media screen and (min-width: 640px) {
  .promo .container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}
@media screen and (min-width: 640px) {
  .promo .container_pages {
    align-items: center;
    justify-content: center;
  }
}
.promo__title {
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  color: #171219;
  margin-bottom: 12px;
}
@media screen and (min-width: 360px) {
  .promo__title {
    font-size: 24px;
    text-align: left;
  }
}
@media screen and (min-width: 480px) {
  .promo__title {
    text-align: center;
    justify-content: center;
  }
}
@media screen and (min-width: 640px) {
  .promo__title {
    text-align: left;
    font-size: 28px;
  }
}
@media screen and (min-width: 768px) {
  .promo__title {
    margin-bottom: 15px;
    text-align: left;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 1220px) {
  .promo__title {
    font-size: 32px;
  }
}
.promo__title_pages {
  text-align: left;
}
@media screen and (min-width: 480px) {
  .promo__title_pages {
    text-align: left;
  }
}
@media screen and (min-width: 640px) {
  .promo__title_pages {
    text-align: center;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .promo__title_pages {
    margin-bottom: 12px;
    text-align: center;
    justify-content: center;
  }
}
.promo__text {
  font-size: 14px;
  color: #171219;
}
.promo__text a {
  color: #171219;
  font-weight: bold;
  text-decoration: underline;
}
.promo__text a:hover {
  text-decoration: none;
}
@media screen and (min-width: 480px) {
  .promo__text {
    text-align: center;
  }
}
@media screen and (min-width: 640px) {
  .promo__text {
    text-align: left;
  }
}
@media screen and (min-width: 1024px) {
  .promo__text {
    font-size: 16px;
  }
}
.promo__text_pages {
  text-align: left;
  margin-bottom: 0;
  display: none;
}
@media screen and (min-width: 480px) {
  .promo__text_pages {
    text-align: left;
  }
}
@media screen and (min-width: 640px) {
  .promo__text_pages {
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .promo__text_pages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 4px;
  }
}
@media screen and (min-width: 768px) {
  .promo__text_pages-year {
    display: block;
    flex-wrap: unset;
  }
}
@media screen and (min-width: 640px) {
  .promo__wrapper {
    width: 100%;
    align-self: center;
    padding-bottom: 30px;
    padding-top: 30px;
    margin-right: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .promo__wrapper {
    margin-right: 0px;
    max-width: 370px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media screen and (min-width: 1220px) {
  .promo__wrapper {
    max-width: 460px;
  }
}
@media screen and (min-width: 640px) {
  .promo__wrapper_pages {
    padding: 0;
    margin-right: 0;
  }
}
@media screen and (min-width: 1024px) {
  .promo__wrapper_pages {
    max-width: unset;
  }
}
@media screen and (min-width: 1220px) {
  .promo__wrapper_pages {
    max-width: unset;
  }
}
.promo__btn {
  max-width: 200px;
  margin: 30px 0 25px;
}
@media screen and (min-width: 480px) {
  .promo__btn {
    margin: 30px auto 25px;
  }
}
@media screen and (min-width: 640px) {
  .promo__btn {
    margin-bottom: 0;
    margin-left: 0;
    margin: 36px 0 0;
  }
}
.promo__break {
  display: none;
}
@media screen and (min-width: 768px) {
  .promo__break {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .promo__break_pages {
    display: none;
  }
}
.promo__img-wrapper {
  max-width: 315px;
  width: 100%;
  position: relative;
  margin: 0 auto;
}
@media screen and (min-width: 480px) {
  .promo__img-wrapper {
    max-width: 350px;
  }
}
@media screen and (min-width: 640px) {
  .promo__img-wrapper {
    max-width: 330px;
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .promo__img-wrapper {
    max-width: 385px;
  }
}
@media screen and (min-width: 1024px) {
  .promo__img-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    max-width: 550px;
  }
}
@media screen and (min-width: 1220px) {
  .promo__img-wrapper {
    max-width: 690px;
  }
}
.promo__img {
  max-width: 280px;
  width: 100%;
  position: relative;
  z-index: 2;
  margin: 0 auto;
}
@media screen and (min-width: 480px) {
  .promo__img {
    max-width: 380px;
  }
}
@media screen and (min-width: 640px) {
  .promo__img {
    max-width: 320px;
  }
}
@media screen and (min-width: 768px) {
  .promo__img {
    max-width: 385px;
  }
}
@media screen and (min-width: 1024px) {
  .promo__img {
    max-width: 530px;
  }
}
@media screen and (min-width: 1220px) {
  .promo__img {
    max-width: 100%;
  }
}
.promo__img--small {
  max-width: 200px;
  padding-top: 30px;
}
@media screen and (min-width: 480px) {
  .promo__img--small {
    max-width: 220px;
  }
}
@media screen and (min-width: 640px) {
  .promo__img--small {
    margin-top: 20px;
    max-width: 230px;
  }
}
@media screen and (min-width: 768px) {
  .promo__img--small {
    margin-top: 20px;
    max-width: 250px;
  }
}
@media screen and (min-width: 1024px) {
  .promo__img--small {
    margin-top: 10px;
    max-width: 370px;
  }
}
.promo__circle {
  position: absolute;
  width: 320px;
  height: 320px;
  left: 50%;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: #51225D;
  border-radius: 50%;
}
@media screen and (min-width: 480px) {
  .promo__circle {
    width: 380px;
    height: 380px;
  }
}
@media screen and (min-width: 640px) {
  .promo__circle {
    width: 320px;
    height: 320px;
  }
}
@media screen and (min-width: 768px) {
  .promo__circle {
    width: 385px;
    height: 385px;
  }
}
@media screen and (min-width: 1024px) {
  .promo__circle {
    width: 550px;
    height: 550px;
    top: auto;
    bottom: -215px;
  }
}
@media screen and (min-width: 1220px) {
  .promo__circle {
    width: 690px;
    height: 690px;
    top: auto;
    bottom: -150px;
  }
}

.breadcrumbs {
  padding-top: 15px;
  padding-bottom: 15px;
}

.breadcrumbs .container {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .breadcrumbs .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.breadcrumbs-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
@media screen and (min-width: 640px) {
  .breadcrumbs-list {
    gap: 8px;
  }
}

.breadcrumbs-item {
  position: relative;
}
.breadcrumbs-item:not(:first-child) {
  padding-left: 15px;
}
.breadcrumbs-item:not(:first-child):before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  background: url("../img/play-arrow.svg") no-repeat;
  background-size: 100%;
  transform: translateY(-50%);
}
@media screen and (min-width: 1024px) {
  .breadcrumbs-item {
    cursor: pointer;
  }
}

.breadcrumbs-item__link {
  display: block;
  font-size: 13px;
  line-height: 1.2;
  color: #9F9F9F;
}
@media screen and (min-width: 540px) {
  .breadcrumbs-item__link {
    font-size: 14px;
  }
}

.breadcrumbs-item__link.active {
  color: #51225D;
  cursor: default;
  text-transform: capitalize;
}

.breadcrumbs-page {
  display: none;
}
@media screen and (min-width: 768px) {
  .breadcrumbs-page {
    display: flex;
    flex-shrink: 0;
  }
}

.breadcrumbs-page__link {
  color: #7F7F7F;
  font-size: 14px;
  font-weight: 700;
  flex-shrink: 0;
}

.catalog {
  padding-top: 20px;
  padding-bottom: 40px;
}

.update-date {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  color: #171219;
  font-size: 16px;
  font-weight: 400;
}
.update-date__img {
  margin-right: 8px;
}
.update-date__img path {
  stroke: #ff9518;
}
@media screen and (min-width: 1024px) {
  .update-date {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 768px) {
  .update-date {
    justify-content: flex-end;
    margin-bottom: 12px;
  }
}

.catalog-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  counter-reset: section;
  gap: 15px;
}
@media screen and (min-width: 640px) {
  .catalog-list {
    align-items: stretch;
  }
}
@media screen and (min-width: 1024px) {
  .catalog-list {
    gap: 20px;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }
}

.catalog-item__title {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;
}
@media screen and (min-width: 1024px) {
  .catalog-item__title {
    max-width: 260px;
    margin-right: 80px;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0;
  }
}

.catalog-item__rating {
  text-align: center;
  font-size: 24px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

@media screen and (min-width: 1024px) {
  .catalog-item__rating--mob {
    display: none;
  }
}

.catalog-item__rating--desk {
  display: none;
}
@media screen and (min-width: 1024px) {
  .catalog-item__rating--desk {
    display: flex;
  }
}

.catalog-item__advanteges {
  position: relative;
  font-size: 14px;
  padding-left: 15px;
  color: #171219;
}
.catalog-item__advanteges:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  background: url("../img/play-arrow.svg") no-repeat;
  background-size: 100%;
  transform: translateY(-50%);
}
.catalog-item__advanteges:not(:last-of-type) {
  margin-bottom: 10px;
}

.catalog-item__more {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  color: #51225D;
  margin-top: 15px;
  margin-bottom: 20px;
}
@media screen and (min-width: 1024px) {
  .catalog-item__more {
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.catalog-item {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 100%;
  padding: 25px 20px;
  position: relative;
  counter-increment: section;
  background: #f2f6f6;
  box-shadow: 0 4px 16px rgba(23, 18, 25, 0.1);
}
.catalog-item__bottom {
  margin-top: auto;
}
@media screen and (min-width: 1024px) {
  .catalog-item__bottom {
    margin-top: unset;
  }
}
.catalog-item:before {
  content: counter(section) "";
  padding: 10px;
  top: 0;
  left: 0;
  width: 38px;
  height: 47px;
  text-align: center;
  position: absolute;
  font-size: 16px;
  line-height: 1;
  color: white;
  z-index: 2;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 80%, 0 100%);
  background-color: #97c9e8;
}
@media screen and (min-width: 640px) {
  .catalog-item {
    max-width: 280px;
  }
}
@media screen and (min-width: 768px) {
  .catalog-item {
    max-width: 330px;
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .catalog-item {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    width: 100%;
    padding: 25px 40px 25px 55px;
  }
}

.catalog-item-cursor {
  cursor: pointer;
}

.catalog-item__title img {
  margin: 0 auto;
  max-width: 170px;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .catalog-item__title img {
    margin: 0;
  }
}

.catalog-item__title span {
  display: none;
}
@media screen and (min-width: 1024px) {
  .catalog-item__title span {
    display: block;
    text-align: left;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 10px;
  }
}

.catalog-item__visit {
  text-transform: uppercase;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .catalog-item__visit {
    margin: 0;
    max-width: 210px;
  }
}

@media screen and (min-width: 1024px) {
  .catalog-item__bottom {
    width: 100%;
    max-width: 215px;
    margin-left: auto;
  }
}

@media screen and (min-width: 1024px) {
  .catalog__question {
    cursor: pointer;
  }
}

.catalog__tooltip {
  width: 18px;
  height: 18px;
}

.catalog-item--popular {
  position: relative;
  border: 2px solid #ff9518;
  padding-top: 50px;
}
.catalog-item--popular:before {
  background-color: #ff9518;
}
.catalog-item--popular:after {
  position: absolute;
  content: "🔥 Popular Choice 🔥";
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 8px;
  text-align: center;
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 52% 90%, 50% 100%, 48% 90%, 0 90%);
  background-color: #ff9518;
  line-height: 1.2;
  font-size: 16px;
  width: 188px;
  height: 48px;
}
@media screen and (min-width: 1024px) {
  .catalog-item--popular {
    padding-top: 25px;
  }
  .catalog-item--popular:after {
    transform: none;
    left: 55px;
  }
}

.catalog-item--popular-background {
  background-color: rgba(222, 110, 87, 0.24);
}

.catalog-item--popular-asian:after {
  content: "🔥 Senior Dating";
}

.catalog-item--popular-slavic:after {
  content: "🔥 Adventure Seekers";
}

.catalog-item--popular-latina:after {
  content: "🔥 Niche Dating";
}

.catalog-item__advanteges--none {
  display: none;
}
@media screen and (min-width: 1024px) {
  .catalog-item__advanteges--none {
    display: block;
  }
}

.blog-post {
  padding-bottom: 80px;
  padding-top: 40px;
}

.blog-post__wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.blog__text {
  font-size: 16px;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .blog__text {
    font-size: 18px;
  }
}

.blog__list {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  padding-bottom: 30px;
}
@media screen and (min-width: 640px) {
  .blog__list {
    max-width: 650px;
  }
}
@media screen and (min-width: 1024px) {
  .blog__list {
    max-width: 100%;
    display: flex;
    gap: 20px;
    padding-bottom: 0px;
  }
}

/* the slides */
.blog__list .slick-slide {
  margin: 0 15px;
  margin-bottom: 20px;
}

/* the parent */
.blog__list .slick-list {
  margin: 0 -15px;
}

.blog__list .slick-next, .slick-prev {
  display: none !important;
}

.blog__list .slick-dots li button {
  width: 10px;
  height: 10px;
}

.blog__list .slick-dots li {
  border: 1px solid #51225D;
  width: 18px;
  height: 18px;
  padding: 3px;
  border-radius: 50%;
}

.blog__list .slick-dots li button:before {
  width: 10px;
  height: 10px;
}

.blog__list .slick-dots li.slick-active button {
  padding: 0;
  width: 10px;
  height: 10px;
  background: #51225D;
  border-radius: 50%;
}

.blog__list .slick-dots {
  bottom: 0;
}

.blog {
  padding-top: 40px;
  padding-bottom: 40px;
}

.blog__subtitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
}
@media screen and (min-width: 768px) {
  .blog__subtitle {
    font-size: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .blog__subtitle {
    font-size: 28px;
  }
}

.blog__text {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 40px;
}

.steps__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .steps__title {
    font-size: 22px;
  }
}
@media screen and (min-width: 1024px) {
  .steps__title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.steps__text {
  font-size: 18px;
  line-height: 30px;
}

.steps__text:not(:last-of-type) {
  margin-bottom: 40px;
}

.steps__bio {
  padding: 20px;
  padding-bottom: 0;
  border-radius: 0;
  background-color: #51225D;
  background-image: url("../img/bio-shadow.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 55px;
}
@media screen and (min-width: 1024px) {
  .steps__bio {
    padding: 40px;
    padding-bottom: 0;
    padding-top: 30px;
  }
}
.steps__bio.steps__bio--slavic-1 {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (min-width: 480px) {
  .steps__bio.steps__bio--slavic-1 {
    padding: 20px 0 0;
  }
}

@media screen and (min-width: 640px) {
  .steps__bio-wrap {
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: center;
  }
}
@media screen and (min-width: 1024px) {
  .steps__bio-wrap {
    justify-content: flex-start;
  }
}

.steps__bio-list {
  margin-bottom: 12px;
}

.steps__bio-item {
  position: relative;
  padding-left: 10px;
}
.steps__bio-item:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 6px;
  background: url("../img/arrow-bio.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.steps__bio-item:not(:last-of-type) {
  margin-bottom: 8px;
}

.steps__bio-info {
  display: none;
}
@media screen and (min-width: 640px) {
  .steps__bio-info {
    display: block;
    color: white;
    max-width: 300px;
    width: 100%;
  }
}

.steps__bio-info h4 {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
}

.steps__bio-img {
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 640px) {
  .steps__bio-img {
    margin: 0;
  }
}

.steps__bio-title {
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  margin-bottom: 25px;
}
@media screen and (min-width: 768px) {
  .steps__bio-title {
    font-size: 18px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .steps__bio-title {
    font-size: 20px;
    margin-bottom: 35px;
  }
}

@media screen and (min-width: 1024px) {
  .blog__left {
    width: 100%;
    margin-right: 40px;
  }
}

.blog__aside {
  display: none;
}
@media screen and (min-width: 1024px) {
  .blog__aside {
    display: block;
    flex-shrink: 0;
    width: 300px;
    margin-top: 0px;
  }
}

.blog__wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .blog__wrapper {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) {
  .aside__title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .blog__aside .post__img-wrap img {
    object-fit: cover;
    width: 100%;
    height: 120px;
    overflow: hidden;
  }
}

@media screen and (min-width: 1024px) {
  .blog__aside .post__img-wrap {
    height: 120px;
    overflow: hidden;
  }
}

@media screen and (min-width: 1024px) {
  .blog__aside .post:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 640px) {
  .steps__bio--second .steps__bio-wrap {
    flex-direction: row-reverse;
  }
}

.steps__bio--slavic-2 .steps__bio-wrap,
.steps__bio--asian-1 .steps__bio-wrap {
  width: calc(100% + 40px);
  margin-left: -20px;
}
@media screen and (min-width: 480px) {
  .steps__bio--slavic-2 .steps__bio-wrap,
.steps__bio--asian-1 .steps__bio-wrap {
    width: 100%;
    margin: 0;
  }
}

.faq__wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.faq {
  padding-top: 40px;
  padding-bottom: 40px;
}
.faq__text {
  margin-bottom: 40px;
}
.faq__title {
  margin-bottom: 25px;
}
.faq__item {
  position: relative;
}
.faq__item:not(:last-child) {
  margin-bottom: 24px;
}
.faq__item.open {
  padding-top: 30px;
  background: #F2F6F6;
  padding-bottom: 15px;
  box-shadow: none;
  border-radius: 0;
}
.faq__item-title {
  padding-left: 10px;
  padding-right: 35px;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  min-height: 34px;
  cursor: pointer;
  padding-bottom: 24px;
}
.faq__item-title:before {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #7F7F7F;
}
.open .faq__item-title:before {
  width: calc(100% - 20px);
}
.faq__item-title:after {
  content: "";
  display: block;
  position: absolute;
  right: 10px;
  top: 0;
  width: 14px;
  height: 14px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.46876 9.89941C0.46876 9.2744 0.975434 8.76773 1.60045 8.76773L18.1985 8.76773C18.8235 8.76773 19.3302 9.27441 19.3302 9.89942C19.3302 10.5244 18.8235 11.0311 18.1985 11.0311L1.60045 11.0311C0.975435 11.0311 0.468761 10.5244 0.46876 9.89941Z' fill='%23333333'/%3E%3Cpath d='M9.8995 19.3301C9.27449 19.3301 8.76781 18.8235 8.76781 18.1985L8.76781 1.60037C8.76781 0.975353 9.27448 0.46868 9.89949 0.46868C10.5245 0.46868 11.0312 0.975354 11.0312 1.60037L11.0312 18.1985C11.0312 18.8235 10.5245 19.3301 9.8995 19.3301Z' fill='%23333333'/%3E%3C/svg%3E");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.open .faq__item-title:after {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='4' viewBox='0 0 20 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.46876 1.89941C0.46876 1.2744 0.975434 0.767727 1.60045 0.767727L18.1985 0.767732C18.8235 0.767732 19.3302 1.27441 19.3302 1.89942C19.3302 2.52443 18.8235 3.03111 18.1985 3.03111L1.60045 3.0311C0.975435 3.0311 0.468761 2.52443 0.46876 1.89941Z' fill='%23333333'/%3E%3C/svg%3E%0A");
}
.faq__item-text {
  margin-bottom: 0;
  color: #666666;
  max-height: 0;
  overflow: hidden;
  font-size: 14px;
  transition: all 0.8s;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
}
.open .faq__item-text {
  transition: all 0.8s;
  max-height: 300px;
  margin-top: 25px;
}
@media screen and (min-width: 1024px) {
  .faq__text {
    font-size: 18px;
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 768px) {
  .faq__text {
    margin-bottom: 60px;
  }
  .faq-title {
    font-size: 18px;
  }
}
@media screen and (min-width: 480px) {
  .faq__item {
    padding-bottom: 0;
  }
  .faq__item-title {
    min-height: 1px;
  }
  .faq__item-text {
    font-size: 16px;
  }
  .faq__item.open {
    padding-bottom: 25px;
  }
}

.post {
  display: block;
  box-shadow: 0 4px 16px rgba(23, 18, 25, 0.1);
  margin-bottom: 20px;
  max-width: 320px;
  width: 100%;
  border-radius: 0;
  overflow: hidden;
}
.post .post__wrapper,
.post .post__img-wrap,
.post .post__img path,
.post .post__category,
.post .post__date {
  transition: 0.5s;
}
.post__img path {
  fill: #51225D;
}
.post:hover .post__wrapper {
  background: #51225D;
  color: white;
}
.post:hover .post__img path {
  fill: #fff;
}
.post:hover .post__category {
  color: white;
}
.post:hover .post__title {
  color: #fff;
  transition: 0.5s;
}
.post:hover .post__date {
  color: white;
  opacity: 0.5;
}
.post:hover .post__img-wrap {
  position: relative;
}
.post:hover .post__img-wrap:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 15;
  transition: 0.5s;
}
.post:active .post__wrapper {
  background: #450954;
}
@media screen and (min-width: 1024px) {
  .post {
    margin-bottom: 0;
  }
}

.post__info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.post__date {
  color: #7F7F7F;
  font-size: 14px;
}

.post__category {
  color: #51225D;
  font-size: 14px;
  text-transform: uppercase;
}

.post__title {
  transition: 0.5s;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
  color: #171219;
}
@media screen and (min-width: 1024px) {
  .post__title:hover {
    text-decoration: underline;
  }
}

.post__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background: #F2F6F6;
  min-height: 200px;
}

.post__link {
  width: 36px;
  display: block;
  margin-left: auto;
}
@media screen and (min-width: 1024px) {
  .post__link {
    margin: 0;
  }
}

.post__img-wrap {
  width: 100%;
  position: relative;
}
.post__img-wrap:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(81, 34, 93, 0.75);
  transition: 0.5s;
}

.quiz-block {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}

.quiz__wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.quiz__text {
  font-size: 18px;
  margin-bottom: 40px;
}
@media screen and (min-width: 1024px) {
  .quiz__text {
    margin-bottom: 45px;
  }
}

.interactive__bg {
  overflow: hidden;
  padding: 50px 20px 0 20px;
  background-color: #51225D;
  background-image: url("../img/quiz-shadow.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  height: 100%;
  border-radius: 0;
  box-shadow: none;
}
@media screen and (min-width: 375px) {
  .interactive__bg {
    padding: 40px 20px 0px 20px;
  }
}
@media screen and (min-width: 480px) {
  .interactive__bg {
    padding: 50px 20px 0px 20px;
  }
}
@media screen and (min-width: 1024px) {
  .interactive__bg {
    padding: 55px 0 0 20px;
    padding-top: 80px;
    padding-left: 40px;
    height: 500px;
  }
}

.interactive {
  position: relative;
}

.interactive.active .interactive__bg {
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 40px 10px 12px 10px;
}
.interactive.active .interactive__bg:before {
  position: absolute;
  content: "";
  background-color: #97C9E8;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  bottom: -160px;
  width: 570px;
  height: 520px;
}
@media screen and (min-width: 480px) {
  .interactive.active .interactive__bg {
    padding: 40px 28px 10px 28px;
  }
  .interactive.active .interactive__bg:before {
    bottom: -260px;
    width: 622px;
    height: 628px;
  }
}
@media screen and (min-width: 640px) {
  .interactive.active .interactive__bg {
    padding: 45px 40px 40px 40px;
  }
  .interactive.active .interactive__bg:before {
    bottom: -290px;
    bottom: -370px;
    width: 760px;
    height: 730px;
  }
}
@media screen and (min-width: 1024px) {
  .interactive.active .interactive__bg {
    background-size: 100%;
    padding: 60px 60px 65px 60px;
  }
  .interactive.active .interactive__bg:before {
    top: 160px;
    right: -146px;
    width: 1500px;
    height: 1500px;
  }
}

.interactive__right {
  width: 100%;
  padding-top: 30px;
  max-width: 280px;
  position: relative;
}
.interactive__right:after {
  content: "";
  position: absolute;
  top: 0;
  right: 15px;
  width: 49px;
  height: 52px;
  background: url("../img/clap.png") no-repeat center;
  background-size: 100%;
  z-index: 2;
}
.interactive__right:before {
  position: absolute;
  content: "";
  background-color: #97C9E8;
  border-radius: 50%;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  height: 525px;
}
@media screen and (min-width: 375px) {
  .interactive__right {
    width: 100%;
    max-width: 315px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 768px) {
  .interactive__right {
    max-width: 325px;
  }
  .interactive__right:before {
    width: 522px;
    height: 528px;
  }
}
@media screen and (min-width: 1024px) {
  .interactive__right {
    padding-top: 0px;
    margin-right: 0;
    max-width: 505px;
  }
  .interactive__right:after {
    top: -40px;
    width: 67px;
    height: 70px;
  }
  .interactive__right:before {
    transform: none;
    left: auto;
    top: -29px;
    right: -166px;
    width: 722px;
    height: 728px;
  }
}

.interactive__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 640px) {
  .interactive__column {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1024px) {
  .interactive__column {
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
  }
}

.interactive__title {
  width: 100%;
  max-width: 280px;
  font-size: 17px;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
  text-transform: uppercase;
}
@media screen and (min-width: 375px) {
  .interactive__title {
    text-align: center;
    margin: 0 auto 10px;
  }
}
@media screen and (min-width: 480px) {
  .interactive__title {
    max-width: 470px;
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 640px) {
  .interactive__title {
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  .interactive__title {
    font-size: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .interactive__title {
    text-align: left;
    margin-left: 0;
    margin-bottom: 30px;
    font-size: 28px;
    max-width: 630px;
  }
}

.interactive__subtitle {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
  color: white;
}
@media screen and (min-width: 375px) {
  .interactive__subtitle {
    max-width: 280px;
    margin: 0 auto 15px;
  }
}
@media screen and (min-width: 480px) {
  .interactive__subtitle {
    max-width: 470px;
    margin: 0 auto 18px;
  }
}
@media screen and (min-width: 768px) {
  .interactive__subtitle {
    font-size: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .interactive__subtitle {
    font-size: 18px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .interactive__subtitle--mob {
    display: none;
  }
}

.interactive__subtitle--desk {
  display: none;
}
@media screen and (min-width: 1024px) {
  .interactive__subtitle--desk {
    display: block;
  }
}

.interactive__btn {
  font-weight: bold;
  max-width: 210px;
  width: 100%;
  transition: 0.3s;
  background: #97c9e8;
  text-transform: uppercase;
  color: white;
  padding: 18px;
  margin-bottom: 5px;
  border-radius: 0;
  cursor: pointer;
}
.interactive__btn:hover {
  text-decoration: underline;
  background-color: #8ebed9;
}
.interactive__btn:active {
  color: #97C9E8;
  border: 1px solid #97C9E8;
  background-color: white;
  text-decoration: none;
}
@media screen and (min-width: 375px) {
  .interactive__btn {
    margin: 0 auto 5px;
  }
}
@media screen and (min-width: 480px) {
  .interactive__btn {
    margin: 0 auto 15px;
  }
}
@media screen and (min-width: 640px) {
  .interactive__btn {
    margin: 0 auto 0;
  }
}
@media screen and (min-width: 1024px) {
  .interactive__btn {
    cursor: pointer;
    margin-left: 0;
  }
}

.interactive__img {
  border-radius: 30px;
  border: 10px solid #F2F6F6;
  position: relative;
  z-index: 2;
  background-color: transparent;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
@media screen and (min-width: 375px) {
  .interactive__img {
    width: 100%;
  }
}
@media screen and (min-width: 480px) {
  .interactive__img {
    height: auto;
  }
}
@media screen and (min-width: 640px) {
  .interactive__img {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 330px;
    border-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .interactive__img {
    max-width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .interactive__img {
    border-top-right-radius: 0;
    border-right: 0;
  }
}

.interactive__left {
  align-self: flex-start;
  width: 100%;
  max-width: 250px;
}
@media screen and (min-width: 375px) {
  .interactive__left {
    text-align: center;
    max-width: 280px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 480px) {
  .interactive__left {
    max-width: 470px;
  }
}
@media screen and (min-width: 768px) {
  .interactive__left {
    text-align: left;
  }
}
@media screen and (min-width: 1024px) {
  .interactive__left {
    max-width: 380px;
    margin: 0;
  }
}

.interactive-block {
  margin-top: 80px;
}

.quiz-box {
  position: relative;
  z-index: 2;
  height: 100%;
}

.quiz-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.quiz-box h2 {
  text-transform: uppercase;
  text-align: center;
  padding: 0;
  margin: 0 0 8px;
  color: white;
  font-weight: bold;
  font-size: 17px;
}
@media screen and (min-width: 375px) {
  .quiz-box h2 {
    font-size: 18px;
  }
}
@media screen and (min-width: 640px) {
  .quiz-box h2 {
    font-size: 22px;
  }
}
@media screen and (min-width: 768px) {
  .quiz-box h2 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .quiz-box h2 {
    font-size: 28px;
  }
}

.quiz-box__list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto;
}
@media screen and (min-width: 640px) {
  .quiz-box__list {
    margin-bottom: auto;
    gap: 10px;
  }
}
@media screen and (min-width: 1024px) {
  .quiz-box__list {
    gap: 20px;
  }
}

.quiz-box__item {
  width: 100%;
  max-width: 300px;
  height: 118px;
  background: #F2F6F6;
  overflow: hidden;
  font-size: 20px;
  margin: 0;
  box-shadow: 0px 4px 16px rgba(23, 18, 25, 0.08);
  border-radius: 25px;
}
@media screen and (min-width: 640px) {
  .quiz-box__item {
    max-width: 210px;
    height: 170px;
  }
}
@media screen and (min-width: 768px) {
  .quiz-box__item {
    max-width: 260px;
  }
}
@media screen and (min-width: 1024px) {
  .quiz-box__item {
    max-width: 235px;
    height: 230px;
  }
}
@media screen and (min-width: 1220px) {
  .quiz-box__item {
    max-width: 250px;
    height: 240px;
  }
}

.quiz-box__item label img {
  width: 100%;
  display: block;
  object-fit: contain;
  height: 60px;
  margin: 0 auto;
}
@media screen and (min-width: 640px) {
  .quiz-box__item label img {
    height: 100px;
  }
}
@media screen and (min-width: 1024px) {
  .quiz-box__item label img {
    height: 130px;
  }
}
@media screen and (min-width: 1220px) {
  .quiz-box__item label img {
    height: 140px;
  }
}

.quiz-box__item label span {
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: bold;
}
@media screen and (min-width: 1024px) {
  .quiz-box__item label span {
    font-size: 18px;
  }
}

.quiz-box__item label {
  overflow: hidden;
  border: 10px solid #F2F6F6;
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px 0 0;
  border-radius: 25px;
}
@media screen and (min-width: 640px) {
  .quiz-box__item label {
    padding: 10px 0 0;
    border: 10px solid #F2F6F6;
  }
}
@media screen and (min-width: 1024px) {
  .quiz-box__item label {
    padding: 20px 0 0;
  }
}

.quiz-box .answer {
  display: none;
}

.quiz-box .question-text {
  color: white;
  text-align: center;
  margin-bottom: 5px;
}

.counter-next {
  color: rgba(255, 255, 255, 0.6);
}

.quiz-box .animation-result {
  display: none;
  height: 200px;
  background: yellow;
}

.quiz-box .loading {
  display: none;
  position: relative;
  width: 100%;
  text-align: center;
  padding-bottom: 70px;
  padding-top: 100px;
}
@media screen and (min-width: 640px) {
  .quiz-box .loading {
    padding-top: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .quiz-box .loading {
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.quiz-box .loading-text {
  color: white;
  font-size: 18px;
  margin-top: 10px;
}

.quiz-box {
  display: none;
}

.finish {
  display: none;
}

.finish-title {
  max-width: 320px;
  width: 100%;
  margin: 0 auto 16px;
  font-size: 14px;
  text-align: center;
  color: white;
}
@media screen and (min-width: 640px) {
  .finish-title {
    font-size: 16px;
  }
}
@media screen and (min-width: 640px) {
  .finish-title {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  .finish-title {
    max-width: 100%;
    font-size: 18px;
  }
}
@media screen and (min-width: 1024px) {
  .finish-title {
    font-size: 20px;
    margin-bottom: 22px;
  }
}

.finish-box__title {
  font-size: 20px;
  color: #171219;
  font-weight: 700;
  margin-bottom: 10px;
}
@media screen and (min-width: 1024px) {
  .finish-box__title {
    font-size: 24px;
    margin-bottom: 24px;
  }
}

.finish-title span {
  font-weight: bold;
}

.finish-box {
  position: relative;
  max-width: 315px;
  width: 100%;
  margin: 0 auto;
  color: #171219;
  background: white;
  border: 6px solid #f2f6f6;
  border-radius: 15px;
  padding: 6px 6px 10px;
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
@media screen and (min-width: 375px) {
  .finish-box {
    border: 10px solid #f2f6f6;
    border-bottom: none;
    padding: 10px;
  }
}
@media screen and (min-width: 480px) {
  .finish-box {
    max-width: 330px;
    border: 10px solid #f2f6f6;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: none;
  }
}
@media screen and (min-width: 640px) {
  .finish-box {
    max-width: 550px;
    border-bottom: 0;
    padding-bottom: 25px;
    min-height: 345px;
  }
}
@media screen and (min-width: 1024px) {
  .finish-box {
    border: 13px solid #f2f6f6;
    border-top: 7px solid #f2f6f6;
    border-bottom: 0;
    min-height: 360px;
    max-width: 600px;
    width: 100%;
    padding-top: 17px;
    margin: 0 auto;
  }
}

.finish-box__text {
  margin-bottom: 15px;
}

.finish-box__text,
.finish-box__subtitle,
.finish-box__text-second {
  font-size: 14px;
}
@media screen and (min-width: 375px) {
  .finish-box__text,
.finish-box__subtitle,
.finish-box__text-second {
    font-size: 16px;
  }
}

.finish-box .finish__btn {
  display: block;
  text-align: center;
  max-width: 100%;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  margin-top: -25px;
}
@media screen and (min-width: 480px) {
  .finish-box .finish__btn {
    margin-left: 0;
  }
}
@media screen and (min-width: 640px) {
  .finish-box .finish__btn {
    max-width: 210px;
    margin-top: 35px;
  }
}

.finish-box__subtitle {
  margin-bottom: 8px;
}

.finish-img {
  margin-bottom: 10px;
}
@media screen and (min-width: 640px) {
  .finish-img {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .finish-img {
    margin-bottom: 20px;
  }
}

.finish-box__img {
  transition: all 2s;
  opacity: 0;
  transition: 2s;
  width: 130px;
  max-width: 130px;
  margin-left: -30px;
}
@media screen and (min-width: 640px) {
  .finish-box__img {
    opacity: 1;
    width: 100%;
    max-width: 265px;
    margin: 0;
    position: absolute;
    bottom: -140px;
    right: 0;
  }
}
@media screen and (min-width: 768px) {
  .finish-box__img {
    max-width: 295px;
  }
}
@media screen and (min-width: 1024px) {
  .finish-box__img {
    max-width: 320px;
  }
}

.finish-box__circle {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 640px) {
  .finish-box__column {
    display: flex;
    gap: 20px;
    align-items: flex-start;
  }
}

.finish-box__column-right {
  margin-bottom: 10px;
}
@media screen and (min-width: 1024px) {
  .finish-box__column-right {
    max-width: 350px;
    width: 100%;
    margin-bottom: 0;
  }
}

.finish-box__column-left {
  position: relative;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (min-width: 640px) {
  .finish-box__column-left {
    position: static;
    width: 100%;
  }
}

.finish-box__fade-1,
.finish-box__fade-2,
.finish-box__fade-3 {
  opacity: 0;
  transition: all 2s;
}

.finish-box__fade-4 {
  display: none;
}

@media screen and (min-width: 640px) {
  .finish__btn-mob {
    display: none;
  }
}

.circular-chart {
  z-index: 2;
  margin: 0px auto;
  width: 120px;
}
@media screen and (min-width: 640px) {
  .circular-chart {
    width: 145px;
  }
}
@media screen and (min-width: 1024px) {
  .circular-chart {
    width: 165px;
  }
}

.circle-bg {
  fill: none;
  stroke: #F2F6F6;
  stroke-width: 6;
}

.circle {
  stroke-dasharray: 90, 100;
  fill: none;
  stroke-width: 9;
  stroke: #51225D;
  animation: progress 3s ease-out;
}

.percentage {
  fill: #363636;
  font-size: 0.5em;
  text-anchor: middle;
  font-family: Helvetica;
  font-weight: bold;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
.interactive:after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "🔎 Find the Platform";
  color: #fff;
  top: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  padding-bottom: 4px;
  transform: translateX(-50%);
  text-align: center;
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 52% 90%, 50% 100%, 48% 90%, 0 90%);
  background-color: #ff9518;
  line-height: 1.2;
  font-size: 16px;
  width: 188px;
  height: 49px;
  z-index: 3;
}

footer {
  background: #F2F6F6;
  padding-bottom: 40px;
}

footer .logo {
  margin: 0 auto;
}

footer .rate {
  margin-bottom: 0;
}

.footer__logo {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.footer__logo:before {
  position: absolute;
  content: "";
  width: 100vw;
  height: 1px;
  background: rgba(127, 127, 127, 0.5);
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media screen and (min-width: 768px) {
  .footer__logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footer__text {
  margin-bottom: 20px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .footer__text {
    margin-bottom: 0;
    max-width: 320px;
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .footer__text {
    max-width: 525px;
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .footer__wrapper {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  footer .pages {
    flex-direction: column;
    order: 0;
  }
}
@media screen and (min-width: 1024px) {
  footer .pages {
    gap: 10px;
  }
}

footer .links__link,
footer .pages__link {
  color: #171219;
  font-size: 16px;
}
@media screen and (min-width: 768px) {
  footer .links__link,
footer .pages__link {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  footer .links {
    max-width: 180px;
    width: 100%;
    flex-direction: column;
    order: 0;
  }
}
@media screen and (min-width: 1024px) {
  footer .links {
    gap: 10px;
  }
}

@media screen and (min-width: 768px) {
  .footer__right {
    max-width: 190px;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .footer__rate-mob {
    display: none;
  }
}

.footer__rate-desk {
  display: none;
}
@media screen and (min-width: 768px) {
  .footer__rate-desk {
    display: block;
  }
}

.pages__link.active {
  text-decoration: underline;
}

@media screen and (min-width: 375px) {
  .checked {
    padding-top: 16px;
  }
}

.checked-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6px;
}
@media screen and (min-width: 640px) {
  .checked-list {
    gap: 10px;
    max-width: 600px;
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .checked-list {
    justify-content: flex-start;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }
}

.checked-item {
  font-weight: bold;
  font-size: 14px;
  position: relative;
  padding-left: 24px;
}
.checked-item:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: no-repeat center/12px 6px #ff9518 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='4' viewBox='0 0 26 19' fill='none'%3E%3Cpath id='check-icon' fill-rule='evenodd' clip-rule='evenodd' d='M26 3.04019L10.3632 19L0 8.41927L3.01006 5.37934L10.3634 12.8853L22.9902 0L26 3.04019Z' fill='%23ffffff' /%3E%3C/svg%3E");
  background-size: 100%;
}
@media screen and (min-width: 640px) {
  .checked-item {
    padding-left: 30px;
    font-size: 16px;
  }
  .checked-item:before {
    width: 24px;
    height: 24px;
  }
}

@media screen and (min-width: 640px) {
  .checked {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 640px) {
  .checked-list {
    margin: 0 auto;
  }
}

.product {
  padding-top: 40px;
  padding-bottom: 40px;
}

.product__subtitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
}
@media screen and (min-width: 768px) {
  .product__subtitle {
    font-size: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .product__subtitle {
    font-size: 28px;
  }
}

.product__wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .product__wrapper {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1024px) {
  .product__left .product__right-popular {
    display: none;
  }
}

.product__left {
  margin-bottom: 80px;
}
@media screen and (min-width: 1024px) {
  .product__left {
    width: 100%;
    margin-right: 40px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .product__right {
    flex-shrink: 0;
    width: 300px;
    margin-top: 0px;
  }
}

.product__right .product__right-popular {
  display: none;
}
@media screen and (min-width: 1024px) {
  .product__right .product__right-popular {
    display: block;
  }
}

.product__step {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .product__step {
    font-size: 22px;
  }
}
@media screen and (min-width: 1024px) {
  .product__step {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.product__text {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 40px;
}
.product__text a {
  color: #97c9e8;
  font-weight: bold;
  text-decoration: underline;
  white-space: nowrap;
}
.product__text a:hover {
  text-decoration: none;
}

.product__list {
  list-style: decimal;
  padding-left: 25px;
}

.product__item {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 40px;
}

.product__banner {
  padding: 20px;
  padding-bottom: 0;
  background-color: #51225D;
  background-image: url("../img/bio-shadow.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  margin-bottom: 55px;
  margin-top: 40px;
}
@media screen and (min-width: 1024px) {
  .product__banner {
    padding: 40px;
    padding-bottom: 0;
    padding-top: 40px;
  }
}

.product__banner-img {
  margin: 0 auto;
}

.product__banner-title {
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  margin-bottom: 25px;
}
@media screen and (min-width: 768px) {
  .product__banner-title {
    font-size: 18px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .product__banner-title {
    font-size: 20px;
    margin-bottom: 35px;
  }
}

.product__btn {
  max-width: 320px;
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .product__btn {
    max-width: 200px;
    width: 100%;
    margin-left: 0;
  }
}

.product__stats {
  margin-bottom: 55px;
}
@media screen and (min-width: 480px) {
  .product__stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}

.product__stats-wrapper {
  max-width: 100%;
  width: 100%;
  background: #F2F6F6;
  padding: 20px 20px 40px;
}
.product__stats-wrapper:not(:last-of-type) {
  margin-bottom: 20px;
}
@media screen and (min-width: 480px) {
  .product__stats-wrapper:not(:last-of-type) {
    margin-bottom: 0px;
  }
}
@media screen and (min-width: 640px) {
  .product__stats-wrapper {
    max-width: 100%;
    width: calc(50% - 10px);
  }
}

.product__stats-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
}

.product__stats-item {
  font-size: 16px;
  line-height: 22px;
  position: relative;
  padding-left: 15px;
}
.product__stats-item:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  background: url("../img/play-arrow.svg") no-repeat;
  background-size: 100%;
  transform: translateY(-50%);
}
.product__stats-item:not(:last-of-type) {
  margin-bottom: 10px;
}

.product__right-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 35px;
}
@media screen and (min-width: 1024px) {
  .product__right-title {
    font-size: 32px;
    margin-bottom: 25px;
  }
}

.product-banner {
  position: relative;
  background: #f2f6f6;
  box-shadow: 0 4px 16px rgba(23, 18, 25, 0.1);
  padding: 25px 20px;
  max-width: 320px;
  width: 100%;
  margin: 0 auto;
}
.product-banner:before {
  content: "1";
  padding: 10px;
  top: 0;
  left: 0;
  width: 38px;
  height: 47px;
  text-align: center;
  position: absolute;
  font-size: 16px;
  line-height: 1;
  color: white;
  z-index: 2;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 80%, 0 100%);
}
@media screen and (min-width: 640px) {
  .product-banner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .product-banner {
    display: block;
    max-width: 320px;
    margin: 0;
  }
}

.product-banner__title {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto 25px;
}
@media screen and (min-width: 640px) {
  .product-banner__title {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .product-banner__title {
    margin: 0 auto 25px;
    max-width: 200px;
  }
}

.product-banner__rating {
  text-align: center;
  font-size: 24px;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
@media screen and (min-width: 640px) {
  .product-banner__rating {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .product-banner__rating {
    margin-bottom: 35px;
  }
}

.product-banner__question {
  width: 20px;
  height: 20px;
}
@media screen and (min-width: 1024px) {
  .product-banner__question {
    cursor: pointer;
  }
}

.product-banner__info {
  margin-bottom: 35px;
}
@media screen and (min-width: 640px) {
  .product-banner__info {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .product-banner__info {
    margin-bottom: 35px;
  }
}

@media screen and (min-width: 1024px) {
  .product-banner__bottom {
    width: 100%;
    max-width: 215px;
    margin: 0 auto;
  }
}

.product-banner__visit {
  text-transform: uppercase;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .product-banner__visit {
    margin: 0;
    max-width: 210px;
  }
}

.product-banner__advanteges {
  position: relative;
  font-size: 14px;
  padding-left: 15px;
  color: #171219;
}
.product-banner__advanteges:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  background: url("../img/play-arrow.svg") no-repeat;
  background-size: 100%;
  transform: translateY(-50%);
}
.product-banner__advanteges:not(:last-of-type) {
  margin-bottom: 10px;
}

.product-banner--popular {
  position: relative;
  border: 2px solid #ff9518;
  padding-top: 50px;
}
.product-banner--popular:before {
  background-color: #ff9518;
}
.product-banner--popular:after {
  position: absolute;
  content: "🔥 Popular Choice 🔥";
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 8px;
  text-align: center;
  clip-path: polygon(0px 0px, 100% 0px, 100% 90%, 52% 90%, 50% 100%, 48% 90%, 0px 90%);
  background-color: #ff9518;
  line-height: 1.2;
  font-size: 16px;
  width: 188px;
  height: 48px;
}
@media screen and (min-width: 1024px) {
  .product-banner--popular:after {
    transform: none;
    left: 55px;
  }
}

.product-banner--popular-dating:after {
  position: absolute;
  content: "🔥 Niche Dating";
}

.product-banner--popular-senior:after {
  position: absolute;
  content: "🔥 Senior Dating";
}

.product-banner--simple {
  border: 2px solid #97C9E8;
}
.product-banner--simple:before {
  background: url(../img/flag.svg);
}
.product-banner--simple:after {
  display: none;
}

.philitalks .product-banner:before,
.amourfactory .product-banner:before,
.asianfeels .product-banner:before,
.funchatt .product-banner:before,
.amourfeel .product-banner:before {
  content: "4";
}

.asianmelodies .product-banner:before,
.loveswans .product-banner:before,
.latinfeels .product-banner:before,
.bestdates .product-banner:before {
  content: "3";
}

.lovingfeel .product-banner:before,
.lovefort .product-banner:before,
.jollyromance .product-banner:before {
  content: "2";
}

.loverwhirl .product-banner:before,
.rondevo .product-banner:before,
.latamvibe .product-banner:before {
  content: "5";
}

.product__right-popular {
  margin-bottom: 40px;
}

.product-platform__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  column-gap: 20px;
  justify-content: space-around;
  background: #f2f6f6;
  padding: 35px 20px;
}
@media screen and (min-width: 640px) {
  .product-platform__list {
    flex-direction: row;
  }
}
@media screen and (min-width: 1024px) {
  .product-platform__list {
    display: block;
    padding: 35px 10px;
  }
}

.product-platform {
  width: 100%;
  max-width: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.product-platform:not(:last-of-type) {
  border-bottom: 1px solid rgba(127, 127, 127, 0.5);
  padding-bottom: 25px;
  margin-bottom: 25px;
}
@media screen and (min-width: 375px) {
  .product-platform {
    flex-direction: row;
  }
}
@media screen and (min-width: 640px) {
  .product-platform {
    max-width: 275px;
  }
  .product-platform:nth-child(3) {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .product-platform:not(:last-of-type) {
    border-bottom: 1px solid rgba(127, 127, 127, 0.5);
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
}

.product-platform__title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 25px;
}
@media screen and (min-width: 1024px) {
  .product-platform__title {
    margin-bottom: 20px;
  }
}

.product-platform-wrapper {
  max-width: 125px;
  width: 100%;
  margin-bottom: 10px;
}
@media screen and (min-width: 375px) {
  .product-platform-wrapper {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.product-platform__visit {
  padding: 14px;
  text-transform: uppercase;
  max-width: 140px;
  width: 100%;
  margin: 0;
}

.product-platform__rating {
  display: flex;
  font-size: 16px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
}

.product-platform__question {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.product-platform__img {
  margin-bottom: 10px;
}

@media screen and (min-width: 640px) {
  .product-banner--desk {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .product-banner--desk {
    display: block;
  }
}

.product-banner--mob {
  display: none;
}
@media screen and (min-width: 640px) {
  .product-banner--mob {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .product-banner--mob {
    display: none;
  }
}

.product__banner--inner img {
  margin-right: -20px;
}
@media screen and (min-width: 1024px) {
  .product__banner--inner img {
    margin-right: -40px;
  }
}

.service__title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.service a {
  color: #0000EE;
}

.service ul li {
  margin-bottom: 10px;
}

.service ul {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.service p {
  margin-bottom: 20px;
}

.service {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rate-block {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;
}

.rate-block h2 {
  text-transform: uppercase;
  font-size: 20px;
}

.title-some {
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
}

.rate-slider {
  padding-bottom: 80px;
}

.rate-slider__item {
  display: block;
  margin-right: 5px;
  margin-left: 5px;
}
@media screen and (min-width: 640px) {
  .rate-slider__item {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.rate-slider .slick-arrow {
  display: none;
}

.rate-slider .slick-list {
  padding-top: 20px;
}
@media screen and (min-width: 1024px) {
  .rate-slider .slick-list {
    padding: 20px 30% 0 0;
  }
}
@media screen and (min-width: 1220px) {
  .rate-slider .slick-list {
    padding: 20px 23% 0 0;
  }
}
@media screen and (min-width: 1440px) {
  .rate-slider .slick-list {
    padding: 20px 33% 0 0;
  }
}

@media screen and (min-width: 640px) {
  .rate-slider .product-banner--mob {
    display: none;
  }
}

@media screen and (min-width: 640px) {
  .rate-slider .product-banner--desk {
    display: block;
  }
}

.rate-slider .product-banner__rating {
  margin-bottom: 24px;
}
@media screen and (min-width: 640px) {
  .rate-slider .product-banner__rating {
    margin-bottom: 24px;
  }
}

.rate-slider .product-banner__info {
  margin-bottom: 24px;
}
@media screen and (min-width: 640px) {
  .rate-slider .product-banner__info {
    margin-bottom: 24px;
  }
}

.rate-slider .product-banner--popular {
  padding-top: 40px;
}

.rate-slider .product-banner__title {
  align-items: center;
  height: 35px;
}

.rate-slider .product-banner {
  box-shadow: none;
}
@media screen and (min-width: 640px) {
  .rate-slider .product-banner {
    display: block;
    max-width: 320px;
  }
}

.product-banner--mob {
  display: none;
}
@media screen and (min-width: 640px) {
  .product-banner--mob {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .product-banner--mob {
    display: none;
  }
}

.rate-slider__item--1 .product-banner:before {
  content: "1";
}

.rate-slider__item--2 .product-banner:before {
  content: "2";
}

.rate-slider__item--3 .product-banner:before {
  content: "3";
}

.rate-slider__item--4 .product-banner:before {
  content: "4";
}

.rate-slider__item--5 .product-banner:before {
  content: "5";
}

.rate-slider__item--6 .product-banner:before {
  content: "6";
}

.rate-slider__item--7 .product-banner:before {
  content: "7";
}

.rate-slider__item--8 .product-banner:before {
  content: "8";
}

.rate-slider__item--9 .product-banner:before {
  content: "9";
}

.rate-slider__item--10 .product-banner:before {
  content: "10";
}

.rate-slider__item--11 .product-banner:before {
  content: "11";
}

.rate-slider__item--12 .product-banner:before {
  content: "12";
}

.rate-slider__item--13 .product-banner:before {
  content: "13";
}

.rate-slider__item--14 .product-banner:before {
  content: "14";
}

.rate-girls--bg {
  overflow: hidden;
  padding: 50px 0px 0px 0px;
  background-color: #97C9E8;
}
@media screen and (min-width: 640px) {
  .rate-girls--bg {
    padding: 50px 0 0 0;
  }
}
@media screen and (min-width: 1024px) {
  .rate-girls--bg {
    padding-top: 50px;
  }
}

.rate__title {
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: white;
  margin-bottom: 20px;
}

.rate-girls {
  position: relative;
}
.rate-girls:after {
  position: absolute;
  content: "💬 Get into the chat";
  color: #fff;
  top: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 14px 0;
  text-align: center;
  background: url(../img/popular-bg.svg) no-repeat;
  background-position: center;
  line-height: 1.2;
  font-size: 16px;
  width: 188px;
  height: 63px;
  z-index: 2;
}

.rate-girls__info {
  display: none;
  width: 100%;
  position: absolute;
  bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
@media screen and (min-width: 480px) {
  .rate-girls__info {
    padding-left: 30px;
    padding-right: 30px;
    bottom: 30px;
  }
}

.rate-girls__product {
  font-size: 18px;
  color: white;
  text-shadow: 0px 0px 6px rgb(66, 68, 90);
}

.rate-girls__name {
  font-size: 16px;
  display: block;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  margin-bottom: 10px;
  text-shadow: 0px 0px 6px rgb(66, 68, 90);
}
@media screen and (min-width: 480px) {
  .rate-girls__name {
    font-size: 18px;
  }
}
@media screen and (min-width: 640px) {
  .rate-girls__name {
    font-size: 24px;
  }
}

.rate-girls__dislike,
.rate-girls__like {
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #FF9518;
  width: 50px;
  height: 50px;
}
@media screen and (min-width: 480px) {
  .rate-girls__dislike,
.rate-girls__like {
    width: 76px;
    height: 76px;
  }
}

.rate-girls__dislike {
  background: #333333;
  margin-right: 10px;
}
@media screen and (min-width: 480px) {
  .rate-girls__dislike {
    margin-right: 20px;
  }
}

.rate-girls__right {
  display: flex;
}

.rate-girls__item.slick-slide.rate-prev {
  transform: translate(180px, 50px);
}

.rate-girls__item.slick-slide.rate-prev2 {
  transform: translate(480px, 85px);
}

.rate-girls__item.slick-slide.rate-prev3 {
  transform: translate(900px, 85px);
}

.rate-girls__item.slick-slide.rate-next {
  transform: translate(-180px, 50px);
}

.rate-girls__item.slick-slide.rate-next2 {
  position: relative;
  z-index: -2;
  transform: translate(-450px, 85px);
}

.rate-girls__item.slick-slide.rate-next3 {
  transform: translate(-500px, 135px);
  z-index: -3;
}

.rate-girls__slider .slick-slide:after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(81, 34, 93, 0.97);
}

.rate-girls__slider .slick-current.slick-slide:after {
  background: transparent;
  z-index: -1;
}

.rate-girls__item {
  display: block;
  position: relative;
  max-width: 250px;
  height: 297px;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
@media screen and (min-width: 375px) {
  .rate-girls__item {
    max-width: 275px;
    height: 325px;
  }
}
@media screen and (min-width: 480px) {
  .rate-girls__item {
    max-width: 405px;
    height: 420px;
  }
}

.rate-girls__slider .slick-track {
  display: flex;
  align-items: flex-end;
}

.rate-girls__slider .slick-current {
  transition: all 0.9s;
  position: relative;
  width: 100%;
  opacity: 1;
  z-index: 5;
}
.rate-girls__slider .slick-current .rate-girls__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 1024px) {
  .rate-wrapper {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }
}

.pulse {
  animation: pulse-animation 5s infinite;
}

@keyframes pulse-animation {
  50% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
  }
  70% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
.slick-current.active {
  background: red;
}

.rate-like__bg {
  display: none;
  height: 100%;
  width: 100%;
  background: rgba(81, 34, 93, 0.97);
  position: absolute;
  z-index: 6;
  padding: 25px 25px 10px;
}
@media screen and (min-width: 480px) {
  .rate-like__bg {
    padding: 50px 25px 10px;
  }
}

.rate-like__last {
  display: none;
  height: 100%;
  width: 100%;
  background: rgba(81, 34, 93, 0.97);
  position: absolute;
  z-index: 6;
  padding: 15px 15px 10px;
}
@media screen and (min-width: 480px) {
  .rate-like__last {
    padding: 45px 25px 10px;
  }
}

.slick-current.active .rate-like__bg {
  display: flex;
}

.rate-like__chat {
  display: block;
  cursor: pointer;
  max-width: 320px;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  color: white;
  background: #97C9E8;
  text-transform: uppercase;
  padding: 18px;
  text-align: center;
  margin: 0 auto 15px;
}
@media screen and (min-width: 480px) {
  .rate-like__chat {
    font-size: 16px;
    margin: 0 auto 28px;
  }
}

.rate-like__chat--last {
  margin: 0 auto 0px;
}
@media screen and (min-width: 480px) {
  .rate-like__chat--last {
    margin: 0 auto 28px;
  }
}

.rate-like__back {
  cursor: pointer;
  display: block;
  width: 100%;
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.rate-like__img {
  margin: 0 auto 10px;
  max-width: 100px;
  width: 100%;
}
@media screen and (min-width: 375px) {
  .rate-like__img {
    max-width: 115px;
  }
}
@media screen and (min-width: 480px) {
  .rate-like__img {
    max-width: 130px;
    margin: 0 auto 30px;
  }
}

.rate-like__img--last {
  max-width: 150px;
  width: 100%;
}
@media screen and (min-width: 375px) {
  .rate-like__img--last {
    max-width: 180px;
  }
}
@media screen and (min-width: 480px) {
  .rate-like__img--last {
    max-width: 220px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 640px) {
  .rate-like__img--last {
    max-width: 260px;
  }
}

.rate-like__title {
  font-size: 14px;
  color: white;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 700;
}
@media screen and (min-width: 480px) {
  .rate-like__title {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.rate-like__text {
  color: white;
  text-align: center;
  font-size: 14px;
  margin-bottom: 15px;
}
.rate-like__text span {
  font-weight: 700;
}
@media screen and (min-width: 480px) {
  .rate-like__text {
    font-size: 16px;
  }
}

.rate-like__logo {
  margin: 0 auto 5px;
}

.popup-tooltip {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  background: #fff;
  height: auto;
  width: calc(100% - 30px);
  transform: translate(-50%, -50%);
  z-index: 20;
  padding: 15px;
}
@media screen and (min-width: 480px) {
  .popup-tooltip {
    width: 400px;
    height: 280px;
  }
}
@media screen and (min-width: 640px) {
  .popup-tooltip {
    padding: 20px;
    width: 500px;
    height: 280px;
  }
}
@media screen and (min-width: 768px) {
  .popup-tooltip {
    padding: 25px;
    width: 600px;
    height: 320px;
  }
}

.popup-tooltip__close {
  width: 21px;
  height: 21px;
  margin-left: auto;
  margin-bottom: 15px;
}
@media screen and (min-width: 480px) {
  .popup-tooltip__close {
    margin-bottom: 25px;
  }
}

.popup-tooltip__close img {
  cursor: pointer;
  margin-left: auto;
}

.popup-tooltip__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-tooltip__text {
  text-align: center;
  max-width: 350px;
  width: 100%;
  line-height: 25px;
  font-size: 16px;
}

.popup-tooltip__title {
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  color: #171219;
  margin-bottom: 10px;
}

.popup-tooltip__overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(23, 18, 25, 0.96); /* Black background with opacity */
  z-index: 15; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.popup-quiz {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;
  transition: ease 0.5s;
}

.popup-quiz__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popup-quiz__title {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 1.2;
}
@media screen and (min-width: 480px) {
  .popup-quiz__title {
    line-height: 1.3;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz__title {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  .popup-quiz__title {
    font-size: 22px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz__title {
    font-size: 24px;
    margin-bottom: 0px;
  }
}
@media screen and (min-width: 1220px) {
  .popup-quiz__title {
    font-size: 28px;
  }
}

.popup-quiz--bg {
  overflow: hidden;
  padding: 15px 10px 0;
  background-color: #51225D;
  background-image: url("../img/quiz-shadow.png");
  background-position: bottom center;
  background-repeat: no-repeat;
}
@media screen and (min-width: 640px) {
  .popup-quiz--bg {
    background-position: top right;
  }
}

.popup-quiz__right {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;
}
@media screen and (min-width: 480px) {
  .popup-quiz__right {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz__right {
    width: 100%;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 20px;
    align-self: center;
    padding-bottom: 10px;
  }
}
@media screen and (min-width: 768px) {
  .popup-quiz__right {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz__right {
    max-width: 565px;
  }
}

.popup-quiz__right h3 {
  color: #FFF;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 12px;
  text-align: center;
}
@media screen and (min-width: 375px) {
  .popup-quiz__right h3 {
    line-height: 1.3;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz__right h3 {
    line-height: 1.6;
    font-size: 16px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 768px) {
  .popup-quiz__right h3 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz__right h3 {
    font-size: 20px;
    margin-bottom: 40px;
    text-align: left;
  }
}

.popup-quiz__column {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: space-between;
}
@media screen and (min-width: 640px) {
  .popup-quiz__column {
    flex-direction: row;
    align-items: flex-end;
  }
}

.popup-quiz__input {
  font-size: 14px;
  border: 1px solid #FFF;
  border-right: none;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  max-width: 280px;
  padding: 12px 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.popup-quiz__input::placeholder {
  color: #FFF;
}
@media screen and (min-width: 480px) {
  .popup-quiz__input {
    padding: 20px 24px;
  }
}
@media screen and (min-width: 480px) {
  .popup-quiz__input {
    padding: 18px 24px;
    font-size: 16px;
  }
}

.popup-quiz__form {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}
@media screen and (min-width: 480px) {
  .popup-quiz__form {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz__form {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz__form {
    justify-content: flex-start;
  }
}

.popup-quiz__button {
  cursor: pointer;
  position: relative;
  padding: 18px 24px;
  background: #97c9e8;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.popup-quiz__button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: ">";
}
@media screen and (min-width: 768px) {
  .popup-quiz__button {
    width: 100%;
    max-width: 130px;
  }
  .popup-quiz__button:before {
    content: "SUBMIT";
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz__button {
    max-width: 140px;
  }
}

.popup-quiz__close {
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 10px;
}
@media screen and (min-width: 480px) {
  .popup-quiz__close {
    margin-right: 5px;
    margin-bottom: 15px;
  }
}

.popup-quiz__footnote {
  color: white;
  font-size: 12px;
  line-height: 1.2;
}
@media screen and (min-width: 480px) {
  .popup-quiz__footnote {
    line-height: 1.6;
    text-align: center;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz__footnote {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz__footnote {
    text-align: left;
  }
}

.popup-quiz__footnote a {
  color: white;
  text-decoration: underline;
}

.popup-quiz__img {
  position: relative;
  z-index: 2;
}

.popup-quiz__left {
  width: calc(100% + 40px);
  margin-left: -20px;
}
@media screen and (min-width: 640px) {
  .popup-quiz__left {
    width: 100%;
    max-width: 310px;
    margin-left: -25px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz__left {
    max-width: 370px;
    margin-left: -10px;
  }
}

.popup-quiz-img {
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
}

.popup-quiz-slavic {
  display: none;
}

.popup-quiz-slavic .popup-quiz__wrapper {
  flex-direction: column;
}

.popup-quiz-slavic .popup-quiz__title {
  font-size: 15px;
  color: #171219;
}
@media screen and (min-width: 480px) {
  .popup-quiz-slavic .popup-quiz__title {
    font-size: 16px;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz-slavic .popup-quiz__title {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz-slavic .popup-quiz__title {
    font-size: 22px;
  }
}

.popup-quiz-slavic.popup-quiz--bg {
  background: white;
  padding: 0px 10px 10px;
  border: 1px solid #51225D;
}
@media screen and (min-width: 768px) {
  .popup-quiz-slavic.popup-quiz--bg {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.popup-quiz-slavic .popup-quiz__right {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0px;
}
@media screen and (min-width: 640px) {
  .popup-quiz-slavic .popup-quiz__right {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .popup-quiz-slavic .popup-quiz__right {
    max-width: 345px;
    margin: auto;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz-slavic .popup-quiz__right {
    max-width: 390px;
  }
}

.popup-quiz-slavic .popup-quiz__right h3 {
  text-align: center;
  color: #171219;
  margin-bottom: 10px;
}
@media screen and (min-width: 640px) {
  .popup-quiz-slavic .popup-quiz__right h3 {
    margin-bottom: 20px;
    line-height: normal;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz-slavic .popup-quiz__right h3 {
    margin-bottom: 35px;
  }
}

.popup-quiz-slavic .popup-quiz__column {
  flex-direction: column;
}
@media screen and (min-width: 375px) {
  .popup-quiz-slavic .popup-quiz__column {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz-slavic .popup-quiz__column {
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }
}

.popup-quiz-slavic .popup-quiz__input {
  display: block;
  font-size: 14px;
  border: 1px solid #d1d0d1;
  color: #171219;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 16px 24px;
}
.popup-quiz-slavic .popup-quiz__input::placeholder {
  color: #171219;
}
@media screen and (min-width: 640px) {
  .popup-quiz-slavic .popup-quiz__input {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz-slavic .popup-quiz__input {
    max-width: 320px;
    margin-bottom: 20px;
  }
}

.popup-quiz-slavic .popup-quiz__form {
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
@media screen and (min-width: 480px) {
  .popup-quiz-slavic .popup-quiz__form {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz-slavic .popup-quiz__form {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz-slavic .popup-quiz__form {
    margin-bottom: 35px;
    justify-content: flex-start;
  }
}

.popup-quiz-slavic .popup-quiz__button {
  display: block;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 25px 24px;
  background: #51225D;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.popup-quiz-slavic .popup-quiz__button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "SUBMIT";
}
@media screen and (min-width: 1024px) {
  .popup-quiz-slavic .popup-quiz__button {
    max-width: 320px;
  }
}

.popup-quiz-slavic .popup-quiz__close {
  margin-bottom: 5px;
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 3;
}

.popup-quiz-slavic .popup-quiz__footnote {
  color: #171219;
}
@media screen and (min-width: 1024px) {
  .popup-quiz-slavic .popup-quiz__footnote {
    text-align: center;
  }
}

.popup-quiz-slavic .popup-quiz__footnote a {
  color: #171219;
}

@media screen and (min-width: 768px) {
  .popup-quiz-slavic .popup-quiz__left {
    max-width: 454px;
    margin-left: -155px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz-slavic .popup-quiz__left {
    margin-left: -65px;
  }
}

.popup-quiz-slavic .popup-quiz-img {
  max-width: 100%;
  width: 100%;
  margin: 0 auto 10px;
}
@media screen and (min-width: 375px) {
  .popup-quiz-slavic .popup-quiz-img {
    max-width: 360px;
  }
}
@media screen and (min-width: 480px) {
  .popup-quiz-slavic .popup-quiz-img {
    max-width: 330px;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz-slavic .popup-quiz-img {
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .popup-quiz-slavic .popup-quiz-img {
    max-width: 100%;
  }
}

.hide-after.interactive::after {
  display: none;
}

@media screen and (min-width: 375px) {
  .popup-quiz__title--mob {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .popup-quiz__title--mob {
    display: block;
  }
}

.popup-quiz__title--desk {
  display: none;
}
@media screen and (min-width: 375px) {
  .popup-quiz__title--desk {
    display: block;
    padding-right: 33px;
    padding-left: 33px;
    padding-top: 10px;
  }
}
@media screen and (min-width: 480px) {
  .popup-quiz__title--desk {
    padding-right: 45px;
    padding-left: 45px;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz__title--desk {
    padding-top: 35px;
  }
}
@media screen and (min-width: 768px) {
  .popup-quiz__title--desk {
    display: none;
  }
}

.popup-quiz-latina {
  display: none;
}

.popup-quiz-latina .popup-quiz__wrapper {
  flex-direction: column;
}

.popup-quiz-latina .popup-quiz__title {
  font-size: 15px;
  color: #171219;
}
@media screen and (min-width: 480px) {
  .popup-quiz-latina .popup-quiz__title {
    font-size: 16px;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz-latina .popup-quiz__title {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz-latina .popup-quiz__title {
    font-size: 22px;
  }
}

.popup-quiz-latina.popup-quiz--bg {
  background: white;
  padding: 0px 10px 10px;
  border: 1px solid #51225D;
}
@media screen and (min-width: 768px) {
  .popup-quiz-latina.popup-quiz--bg {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.popup-quiz-latina .popup-quiz__right {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0px;
}
@media screen and (min-width: 640px) {
  .popup-quiz-latina .popup-quiz__right {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .popup-quiz-latina .popup-quiz__right {
    max-width: 345px;
    margin: auto;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz-latina .popup-quiz__right {
    max-width: 390px;
  }
}

.popup-quiz-latina .popup-quiz__right h3 {
  text-align: center;
  color: #171219;
  margin-bottom: 10px;
}
@media screen and (min-width: 640px) {
  .popup-quiz-latina .popup-quiz__right h3 {
    margin-bottom: 20px;
    line-height: normal;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz-latina .popup-quiz__right h3 {
    margin-bottom: 35px;
  }
}

.popup-quiz-latina .popup-quiz__column {
  flex-direction: column;
}
@media screen and (min-width: 375px) {
  .popup-quiz-latina .popup-quiz__column {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz-latina .popup-quiz__column {
    max-width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }
}

.popup-quiz-latina .popup-quiz__input {
  display: block;
  font-size: 14px;
  border: 1px solid #d1d0d1;
  color: #171219;
  margin: 0 auto;
  margin-bottom: 10px;
  padding: 16px 24px;
}
.popup-quiz-latina .popup-quiz__input::placeholder {
  color: #171219;
}
@media screen and (min-width: 640px) {
  .popup-quiz-latina .popup-quiz__input {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz-latina .popup-quiz__input {
    max-width: 320px;
    margin-bottom: 20px;
  }
}

.popup-quiz-latina .popup-quiz__form {
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
@media screen and (min-width: 480px) {
  .popup-quiz-latina .popup-quiz__form {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz-latina .popup-quiz__form {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz-latina .popup-quiz__form {
    margin-bottom: 35px;
    justify-content: flex-start;
  }
}

.popup-quiz-latina .popup-quiz__button {
  display: block;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 25px 24px;
  background: #51225D;
  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.popup-quiz-latina .popup-quiz__button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "SUBMIT";
}
@media screen and (min-width: 1024px) {
  .popup-quiz-latina .popup-quiz__button {
    max-width: 320px;
  }
}

.popup-quiz-latina .popup-quiz__close {
  margin-bottom: 5px;
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 3;
}

.popup-quiz-latina .popup-quiz__footnote {
  color: #171219;
}
@media screen and (min-width: 1024px) {
  .popup-quiz-latina .popup-quiz__footnote {
    text-align: center;
  }
}

.popup-quiz-latina .popup-quiz__footnote a {
  color: #171219;
}

.popup-quiz-latina .popup-quiz__left {
  height: 100%;
}
@media screen and (min-width: 768px) {
  .popup-quiz-latina .popup-quiz__left {
    max-width: 454px;
    margin-left: -12px;
  }
}
@media screen and (min-width: 1024px) {
  .popup-quiz-latina .popup-quiz__left {
    margin-left: -12px;
  }
}

.popup-quiz-latina .popup-quiz-img {
  max-width: 100%;
  width: 100%;
  margin: 0 auto 10px;
}
@media screen and (min-width: 375px) {
  .popup-quiz-latina .popup-quiz-img {
    max-width: 360px;
  }
}
@media screen and (min-width: 480px) {
  .popup-quiz-latina .popup-quiz-img {
    max-width: 330px;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz-latina .popup-quiz-img {
    margin: 0;
    object-fit: cover;
    height: 100%;
  }
}
@media screen and (min-width: 768px) {
  .popup-quiz-latina .popup-quiz-img {
    max-width: 100%;
  }
}

.hide-after.interactive::after {
  display: none;
}

@media screen and (min-width: 375px) {
  .popup-quiz__title--mob {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .popup-quiz__title--mob {
    display: block;
  }
}

.popup-quiz__title--desk {
  display: none;
}
@media screen and (min-width: 375px) {
  .popup-quiz__title--desk {
    display: block;
    padding-right: 33px;
    padding-left: 33px;
    padding-top: 10px;
  }
}
@media screen and (min-width: 480px) {
  .popup-quiz__title--desk {
    padding-right: 45px;
    padding-left: 45px;
  }
}
@media screen and (min-width: 640px) {
  .popup-quiz__title--desk {
    padding-top: 35px;
  }
}
@media screen and (min-width: 768px) {
  .popup-quiz__title--desk {
    display: none;
  }
}

.text-tooltip {
  position: relative;
}

.text-tooltip__label {
  font-size: 14px;
  cursor: pointer;
}

.text-tooltip__content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
}

.text-tooltip__label:hover ~ .text-tooltip__content {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 540px) {
  .text-tooltip__label:focus-within ~ .text-tooltip__content {
    opacity: 1;
    visibility: visible;
  }
}